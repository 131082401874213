export function swiperInit() {
	var primarySwiper = new Swiper(".primary-banner .swiper", {
		preventInteractionOnTransition: true,
		observer: true,
		observeParents: true,
		rewind: true,
		speed: 1205,
		loop: false,
		autoplay: {
			delay: 4000,
			pauseOnMouseEnter: true,
		},
		lazy: {
			loadPrevNext: true,
		},
		slidesPerView: 1,
		lazy: {
			loadPrevNext: true,
		},
		navigation: {
			nextEl: ".primary-banner .next",
			prevEl: ".primary-banner .prev",
		},
		pagination: {
			el: ".primary-banner  .swiper-pagination",
			type: "bullets",
			clickable: true,
		},
	});

	var partnerSwiper = new Swiper(".partner-swiper .swiper", {
		loop: true,
		speed: 3000,
		autoplay: {
			delay: 0,
			disableOnInteraction: false,
			pauseOnMouseEnter: false,
		},
		breakpoints: {
			320: {
				slidesPerView: 2,
			},
			576: {
				slidesPerView: 3,
			},
			768: {
				slidesPerView: 4,
			},
			1024: {
				slidesPerView: 5,
			},
			1200: {
				slidesPerView: 6,
			},
		},
	});

	$(".init-swiper .swiper").each(function (index) {
		var $this = $(this);
		$this.addClass("swiper-init-" + index);
		$this
			.parents(".init-swiper")
			.find(".prev")
			.addClass("prev-nav-" + index);
		$this
			.parents(".init-swiper")
			.find(".next")
			.addClass("next-nav-" + index);
		$this
			.parents(".init-swiper")
			.find(".swiper-pagination")
			.addClass("pagination-inst-" + index);

		var swiper = new Swiper(".swiper-init-" + index, {
			preventInteractionOnTransition: true,
			observer: true,
			observeParents: true,
			rewind: true,
			speed: 900,
			loop: false,
			autoplay: {
				delay: 4000,
				pauseOnMouseEnter: true,
			},
			lazy: {
				loadPrevNext: true,
			},
			slidesPerView: "auto",
			navigation: {
				nextEl: ".next-nav-" + index,
				prevEl: ".prev-nav-" + index,
			},
			pagination: {
				el: ".pagination-inst-" + index,
				type: "bullets",
				clickable: true,
				dynamicBullets: true,
				dynamicMainBullets: 1,
			},
		});
	});
}
