import { basicTask } from "./task";
import { swiperInit } from "./swiper";
const bodyWrap = document.querySelector("body");
const sideNav = document.querySelector(".sidenav-group");
const hamburger = document.querySelector(".hamburger");
const menuMobile = document.querySelector(".mobile-nav-wrap");
const searchWrap = document.querySelector(".search-overlay");

document.addEventListener("DOMContentLoaded", () => {
	mappingFunc();
	swiperInit();
	basicTask();
	generalFunction.init();

	// Get all menu from document
	document.querySelectorAll(".triggerButton").forEach(OpenMenu);

	// Menu Open and Close function
	function OpenMenu(active) {
		if (active.classList.contains("triggerButton") === true) {
			active.addEventListener("click", function (e) {
				e.preventDefault();

				if (
					this.nextElementSibling.classList.contains("active") ===
					true
				) {
					// Close the clicked dropdown
					this.parentElement.classList.remove("active");
					this.nextElementSibling.classList.remove("active");
				} else {
					// Close the opend dropdown
					closeMenu();
					// add the open and active class(Opening the DropDown)
					this.parentElement.classList.add("active");
					this.nextElementSibling.classList.add("active");
				}
			});
			active.addEventListener("mouseenter", function (e) {
				e.preventDefault();

				// Close the opend dropdown
				closeMenu();
				// add the open and active class(Opening the DropDown)
				this.parentElement.classList.add("active");
				this.nextElementSibling.classList.add("active");
			});
		}
	}

	// Listen to the doc click
	window.addEventListener("click", function (e) {
		// Close the menu if click happen outside menu
		if (e.target.closest(".radial") === null) {
			// Close the opend dropdown
			closeMenu();
		}
	});

	// Close the openend Menu
	function closeMenu() {
		// remove the open and active class from other opened Moenu (Closing the opend Menu)
		document.querySelectorAll(".radial").forEach(function (container) {
			container.classList.remove("active");
		});

		document.querySelectorAll(".radialMenu").forEach(function (menu) {
			menu.classList.remove("active");
		});
	}
});

window.addEventListener("resize", function () {
	let width = this.window.innerWidth;
	if (width <= 1200.1) {
		mappingFunc();
		generalFunction.init();
	}
});
window.addEventListener("scroll", function () {
	const width = this.window.innerWidth;
	const scrollTop = window.scrollY || document.documentElement.scrollTop;
	if (width >= 1200.1) {
		if (
			document.body.scrollTop > 100 ||
			document.documentElement.scrollTop > 100
		) {
			bodyWrap.classList.add("minimize");
		} else {
			bodyWrap.classList.remove("minimize");
		}
	}
	if (scrollTop > window.innerHeight) {
		sideNav.classList.add("show");
	} else {
		sideNav.classList.remove("show");
	}
	var scrollDistance = $(window).scrollTop();
	// Assign active class to nav links while scolling
	$(".about-section-wrap .about-section-id").each(function (i) {
		if (
			$(this).position().top -
				$(".sticky-nav").outerHeight() -
				$("header").outerHeight() -
				1 <=
			scrollDistance
		) {
			$(".sticky-nav li.active").removeClass("active");
			$(".sticky-nav li").eq(i).addClass("active");
		}
	});
});
document.addEventListener("click", (event) => {
	if (!event.target.closest(".search-toggle, .search-overlay")) {
		searchWrap.classList.remove("is-open");
	}
	if (!event.target.closest(".mobile-nav-wrap, .site-menu-toggle")) {
		menuMobile.classList.remove("is-open");
		bodyWrap.classList.remove("overlay-bg");
		hamburger.classList.remove("is-active");
	}
});

Fancybox.bind("[data-fancybox]", {
	parentEl: document.body[0], // Element containing main structure
});

const generalFunction = {
	nextSection: () => {
		$(".arrow-down").click(function () {
			var $nextElement = $(this).parents(".Module").next();
			if ($nextElement.length) {
				$("html, body")
					.stop(true)
					.animate(
						{
							scrollTop:
								$nextElement.offset().top -
								$("header").outerHeight(),
						},
						600
					);
			}
			return false;
		});
	},
	a11yUser: () => {
		const wrapForms = document.querySelectorAll(".wrap-form");
		wrapForms.forEach(function (form) {
			const submitInput = form.querySelector("input[type='submit']");
			const labelFor = submitInput.getAttribute("name");
			const labelSubmit = form.querySelector(".frm-btnwrap .label");
			if (labelFor !== null) {
				if (labelSubmit !== null) {
					labelSubmit.textContent = "Submit Button";
					labelSubmit.setAttribute("for", labelFor);
				}
			}
		});
	},
	hideElement: () => {
		if ($(window).width() < 1200.1) {
			[".search-wrap", ".nav-primary-menu"]
				.map((selector) => document.querySelector(selector))
				.forEach((el) => (el.style.display = "flex"));
		}
	},
	stickyNav: () => {
		$(".sticky-nav a").on("click", function (event) {
			if (this.hash !== "") {
				let offset =
					$("header").outerHeight() + $(".sticky-nav").outerHeight();
				var hash = this.hash;
				$("html, body").animate(
					{
						scrollTop: $(hash).offset().top - offset,
					},
					800,
					function () {
						// window.location.hash = hash;
					}
				);
			} // End if
		});
	},
	init: () => {
		generalFunction.hideElement();
		generalFunction.nextSection();
		generalFunction.stickyNav();
	},
};

function mappingFunc() {
	new MappingListener({
		selector: ".nav-primary-menu",
		mobileWrapper: ".mobile-nav-wrap",
		mobileMethod: "appendTo",
		desktopWrapper: ".language-wrap",
		desktopMethod: "insertBefore",
		breakpoint: 1200.1,
	}).watch();
	new MappingListener({
		selector: ".search-wrap",
		mobileWrapper: ".mobile-top-nav",
		mobileMethod: "appendTo",
		desktopWrapper: ".site-menu-toggle",
		desktopMethod: "insertBefore",
		breakpoint: 1200.1,
	}).watch();
}
